import React, { useEffect } from "react";
import { LayoutLeads } from "../components/layouts/layout-leads";
import { Container, Row, Col } from "react-bootstrap";
import leadsImg1 from "../images/leads-img-1.jpg";
import leadsImg2 from "../images/leads-img-2.jpg";
import lampIcon from "../images/lamp-icon.svg";
import chatIcon from "../images/chat-icon.svg";
import peopleIcon from "../images/people-icon.svg";
import hourIcon from "../images/hour-icon.svg";
import tagIcon from "../images/tag-icon.svg";
import ScrollIndicator from "../components/shared/scroll-indicator";
import SEO from "../components/shared/seo";
import { LogoText } from "../components/shared/logo";
import {
  IoIosTrendingUp,
  IoMdLaptop,
  IoIosCode,
  IoIosCloud,
  IoIosDoneAll,
} from "react-icons/io";

function Leads() {
  return (
    <>
      <SEO title="Leads" />
      <LayoutLeads>
        <div
          id="leads-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <section className="introduction">
            <Container>
              <Row>
                <Col md={12} lg={6} className="p-0">
                  <img src={leadsImg1} alt="leads-1" className="leads-img" />
                </Col>
                <Col md={12} lg={6}>
                  <div className="leads-content">
                    <p className="font-weight-normal">Tired of struggling with poor and inconsistent support from your current web development provider?</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={6} className="order-2-mobile">
                  <div className="leads-content">
                    <p className="font-weight-normal">Our reliable and experienced team can take care of your web development needs.</p>
                  </div>
                </Col>
                <Col md={12} lg={6} className="p-0">
                  <img src={leadsImg2} alt="leads-2" className="leads-img" />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <h4 className="text-center mt-5 mb-3">Diviv is here to help</h4>
                  <p>
                    At Diviv, we understand how frustrating it can be when your current provider fails to meet your expectations
                    or simply can't offer the support you need. We believe that every business deserves a trustworthy partner who
                    can deliver exceptional web development services and support throughout your journey.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <Container>
              <Row>
                <Col md={12}>
                  <h3 className="text-center m-5">Why Choose Diviv?</h3>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} lg={2}>
                  <img src={lampIcon} alt="lamp-icon" className="mb-3" />
                </Col>
                <Col md={12} lg={10}>
                  <h4>Experience and Expertise</h4>
                  <p>
                    With over 15 years of experience in the industry, we have a solid track record of successfully delivering
                    web development solutions for businesses across various sectors. Our team of skilled developers and designers
                    are well-versed in the latest technologies and best practices.
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} lg={2}>
                  <img src={chatIcon} alt="chat-icon" className="mb-3" />
                </Col>
                <Col md={12} lg={10} >
                  <h4>Tailored Solutions</h4>
                  <p>
                    We understand that every business is unique, and we take the time to listen to your specific requirements.
                    Our team will work closely with you to develop a customized web development strategy that aligns with your
                    goals, ensuring that you receive a solution that meets your needs precisely.
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} lg={2}>
                  <img src={peopleIcon} alt="people-icon" className="mb-3" />
                </Col>
                <Col md={12} lg={10}>
                  <h4>Proactive Support</h4>
                  <p>
                    Unlike your previous provider, we believe in providing exceptional customer support throughout the entire
                    development process and beyond. Our dedicated support team is always ready to assist you, answer your questions,
                    and address any concerns that may arise.
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} lg={2}>
                  <img src={hourIcon} alt="hour-icon" className="mb-3" />
                </Col>
                <Col md={12} lg={10}>
                  <h4>Timely Delivery</h4>
                  <p>
                    We value your time and understand the importance of meeting deadlines. Our team follows a meticulous development process,
                    ensuring that your project is delivered on time, without compromising on quality.

                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} lg={2}>
                  <img src={tagIcon} alt="tag-icon" className="mb-3" />
                </Col>
                <Col md={12} lg={10}>
                  <h4>Competitive Pricing</h4>
                  <p>
                    We believe that exceptional web development services shouldn't break the bank. Our pricing structure is designed
                    to be fair and competitive, providing you with excellent value for your investment.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="ux-measures">
            <Container>
              <Row>
                <Col md={12}>
                  <h3 className="text-center m-5">Our Services</h3>
                  <div className="block-container">
                    <div className="row">
                      <div className="block-half stakeholder">
                        <h5>Website Maintenance and Support</h5>
                        <p>
                          Our team is here to provide ongoing maintenance and support, ensuring that your website remains
                          secure, up-to-date, and functioning optimally.
                        </p>
                      </div>
                      <div className="block-half benchmarking">
                        <h5>Responsive Web Development</h5>
                        <p>We create stunning, user-friendly websites that look great on all devices, from desktop to mobile.</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="block-half cms">
                        <h5>Content Management Systems (CMS)</h5>
                        <p>
                          Take control of your website's content with a user-friendly CMS that allows you to update and manage your site effortlessly.
                        </p>
                      </div>
                      <div className="block-half webdev">
                        <h5>Web Application Development</h5>
                        <p>We can build custom web applications tailored to your specific business needs, streamlining your processes and enhancing efficiency.</p>
                      </div>
                    </div>

                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    Don't let a disappointing web development experience hold you back. Contact Diviv today, and let us take the burden off your shoulders.
                    We are passionate about delivering outstanding web development solutions and helping businesses thrive in the digital world.</p>

                  <p>Take the first step towards a better web development partnership. Fill out the form or give us a call to schedule a free consultation.
                    We look forward to hearing from you and helping you achieve your online goals.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </LayoutLeads>
    </>
  );
}

export default Leads;